/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-12-26",
    versionChannel: "nightly",
    buildDate: "2023-12-26T00:14:57.951Z",
    commitHash: "f60ba002402776146676d9b6e6e5d91f462f65ae",
};
